import React from "react";
import { graphql } from "gatsby";
import * as d3 from "d3";
import { getImage } from "gatsby-plugin-image";
import { getDateRange, getTotals } from "../../utils";
import { TableSection } from "../../sections";
import Layout from "../../../gatsby-theme-hypersite/layout";
import AdditionalResourcesBlock from "../../../components/sections/additional-resources";
import GetTheData from "../../sections/get-the-data";
import Banner from "../../../home/banner";
import IndexAbout from "./about";
import IndexHero from "./hero";

const intFormat = d3.format(",d");
const monthFormat = d3.timeFormat("%B %Y");

// TODO: make an MDX file for this page so metadata can be set via CMS instead of set in gatsby-node

export default function TrackerIndexLayout({ children, ...props }) {
  const data = props.data.allStates.nodes;
  const { stateCount, countyCount, lawsuitTotal } = getTotals(data);
  const dateRange = getDateRange(data);
  const content = props.data.lawsuitTrackerJson;
  const image = getImage(props.data.allFile.nodes[0]);

  const tractChoroplethGeojson = {
    features: props.data.allGeojsonJson.nodes
      .filter((geojson) =>
        geojson.features.some((x) => x.geometry?.type === "MultiPolygon")
      )
      .flatMap((x) => x.features),
  };

  return (
    <>
      <Banner style={{ marginTop: 0 }}>
        <div className="banner-contents">
          Coming soon to the Tracker: Minnesota, California, and other new
          jurisdictions.
        </div>
      </Banner>
      <Layout meta={props.pageContext.frontmatter.meta} {...props}>
        <IndexHero
          stateCount={stateCount}
          countyCount={countyCount}
          lawsuitTotal={intFormat(lawsuitTotal)}
          startDate={monthFormat(dateRange[0])}
          endDate={monthFormat(dateRange[1])}
          content={content.index.hero}
          image={image}
        />
        <IndexAbout content={content.index.about} />
        <TableSection
          dateRange={dateRange}
          view={"states"}
          data={data}
          content={{ ...content.index.table, ...content.table }}
          tractChoroplethGeojson={tractChoroplethGeojson}
        />
        <AdditionalResourcesBlock left={<GetTheData fullWidth={true} />} />
        {children}
      </Layout>
    </>
  );
}

export const query = graphql`
  {
    allFile(filter: { name: { eq: "tracker-hero" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    allGeojsonJson(filter: { region: { in: ["tracts", "zips"] } }) {
      nodes {
        features {
          properties {
            GEOID
            NAME
          }
          geometry {
            type
            coordinates
          }
          type
        }
      }
    }
    allStates(filter: { name: { ne: "Connecticut" } }) {
      nodes {
        geoid
        name
        lawsuits
        completed_lawsuits
        no_rep_percent
        default_judgement
        # NOTE: temporarily ignore new fields
        # post_judgement
        # engagement
        # answers_filed
        lawsuit_history {
          lawsuits
          month
        }
        earliest_lawsuit
        latest_lawsuit
        counties {
          default_judgement
          # NOTE: temporarily ignore new fields
          # post_judgement
          # engagement
          # answers_filed
          geoid
          state
          lawsuits
          disproportionate
          lawsuit_history {
            lawsuits
            month
          }
          name
          no_rep_percent
          tracts {
            geoid
            default_judgement
            # NOTE: temporarily ignore new fields
            # post_judgement
            # engagement
            # answers_filed
            lawsuits
            lawsuits_per_100
            name
            no_rep_percent
            lawsuit_history {
              lawsuits
              month
            }
            percent_asian
            percent_black
            percent_latinx
            percent_other
            percent_white
            median_hhi
            pct_renter
            pct_owner
            household_size_owner
            household_size_renter
            unemployment_rate
            pct_poverty
            pct_noncitizen
            pct_uninsured
            majority
          }
          zips {
            geoid
            default_judgement
            # NOTE: temporarily ignore new fields
            # post_judgement
            # engagement
            # answers_filed
            lawsuits
            lawsuits_per_100
            name
            no_rep_percent
            lawsuit_history {
              lawsuits
              month
            }
            percent_asian
            percent_black
            percent_latinx
            percent_other
            percent_white
            median_hhi
            pct_renter
            pct_owner
            household_size_owner
            household_size_renter
            unemployment_rate
            pct_poverty
            pct_noncitizen
            pct_uninsured
            majority
          }
        }
      }
    }
    lawsuitTrackerJson {
      county {
        collectors {
          DESCRIPTION
          FOOTNOTE
          TITLE
        }
        demographics {
          BREAKDOWN_LABEL
          BREAKDOWN_TITLE
          COUNT_CHART_TITLE
          COUNT_CHART_TOOLTIP
          DESCRIPTION
          FOOTNOTE
          PROPORTION_CHART_TITLE
          PROPORTION_CHART_TOOLTIP
          TITLE
        }
        hero {
          STATS {
            description
            id
          }
        }
        lawsuits {
          DESCRIPTION
          FOOTNOTE
          PANDEMIC_COMPARISON
          TITLE
        }
        map {
          TITLE
          LABEL
          FOOTNOTE
          DESCRIPTION
        }
        table {
          DESCRIPTION
          FOOTNOTE
          TITLE
        }
      }
      state {
        table {
          TITLE
          FOOTNOTE
          DESCRIPTION
        }
        map {
          DESCRIPTION
          FOOTNOTE
          LABEL
          TITLE
        }
        lawsuits {
          DESCRIPTION
          FOOTNOTE
          PANDEMIC_COMPARISON
          TITLE
        }
        hero {
          STATS {
            description
            id
          }
        }
        demographics {
          BREAKDOWN_LABEL
          BREAKDOWN_TITLE
          COUNT_CHART_TITLE
          COUNT_CHART_TOOLTIP
          DESCRIPTION
          FOOTNOTE
          PROPORTION_CHART_TITLE
          PROPORTION_CHART_TOOLTIP
          TITLE
        }
        collectors {
          DESCRIPTION
          FOOTNOTE
          TITLE
        }
      }
      table {
        ZIPS_NOTE
        TOP_LIMIT
        STATES_NOTE
        TEXAS_NOTE
        OHIO_NOTE
        PENNSYLVANIA_NOTE
        REPORT_LINK
        NO_RESULTS
        NORTH_DAKOTA_NOTE
        LAST_UPDATED
        DEFAULT_JUDGEMENTS_HINT
        COUNTIES_NOTE
        TRACTS_NOTE
      }
      index {
        about {
          DESCRIPTION
          LINKS {
            link
            name
          }
          TITLE
        }
        hero {
          SECOND_LINE
          FIRST_LINE
        }
        table {
          DESCRIPTION
          TITLE
        }
        additional {
          DESCRIPTION
          TITLE
        }
      }
    }
  }
`;
